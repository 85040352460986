import BigNumber from 'bignumber.js'
import React, { useCallback, useMemo, useState } from 'react'
import Button from '../../../components/Button'
import Modal, { ModalProps } from '../../../components/Modal'
import ModalActions from '../../../components/ModalActions'
import ModalTitle from '../../../components/ModalTitle'
import TokenInput from '../../../components/TokenInput'
import { getFullDisplayBalance as getRealBalance } from '../../../utils/formatBalance'
import { getFullDisplayBalance as getDisplayBalance } from '../../../utils/formatDisplayBalance'
import { getTokenDecimalsDelta} from '../../../utils/getTokenDecimals'

interface WithdrawModalProps extends ModalProps {
  max: BigNumber
  onConfirm: (amount: string) => void
  tokenName?: string
}

const WithdrawModal: React.FC<WithdrawModalProps> = ({
  onConfirm,
  onDismiss,
  max,
  tokenName = '',
}) => {
  const [val, setVal] = useState('')
  const [realVal, setRealVal] = useState('')
  const [pendingTx, setPendingTx] = useState(false)

  const fullBalance = useMemo(() => {
    return getDisplayBalance(max, tokenName)
  }, [max, tokenName])

  const realFullBalance = useMemo(() => {
    return getRealBalance(max)
  }, [max, tokenName])

  const handleChange = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      setVal(e.currentTarget.value)
      let tokenDecimalsDelta = getTokenDecimalsDelta(tokenName);
      const realVal = new BigNumber(e.currentTarget.value).dividedBy(new BigNumber(10).pow(tokenDecimalsDelta));
      setRealVal(realVal.toFixed())
    },
    [setVal, realFullBalance, fullBalance],
  )

  const handleSelectMax = useCallback(() => {
    setVal(fullBalance)
    setRealVal(realFullBalance)
  }, [fullBalance, realFullBalance])

  return (
    <Modal>
      <ModalTitle text={`Withdraw ${tokenName}`} />
      <TokenInput
        onSelectMax={handleSelectMax}
        onChange={handleChange}
        value={val}
        max={fullBalance}
        symbol={tokenName}
      />
      <ModalActions>
        <Button text="Cancel" variant="secondary" onClick={onDismiss} />
        <Button
          disabled={pendingTx || !val}
          text={pendingTx ? 'Pending Confirmation' : 'Confirm'}
          onClick={async () => {
            setPendingTx(true)
            await onConfirm(realVal)
            setPendingTx(false)
            onDismiss()
          }}
        />
      </ModalActions>
    </Modal>
  )
}

export default WithdrawModal
