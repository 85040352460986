import React, { useCallback } from 'react'
import styled from 'styled-components'
import { useWallet } from 'use-wallet'
import useTokenBalance from '../../../hooks/useTokenBalance'
import useSushi from '../../../hooks/useSushi'
import { getSushiAddress } from '../../../sushi/utils'
import { getBalanceNumber } from '../../../utils/formatBalance'
import Button from '../../Button'
import CardIcon from '../../CardIcon'
import Label from '../../Label'
import Modal, { ModalProps } from '../../Modal'
import ModalActions from '../../ModalActions'
import ModalContent from '../../ModalContent'
import ModalTitle from '../../ModalTitle'
import Spacer from '../../Spacer'
import Value from '../../Value'
import logo from '../../../assets/img/logo-big.png'

const AccountModal: React.FC<ModalProps> = ({ onDismiss }) => {
  const { account, reset } = useWallet()

  const handleSignOutClick = useCallback(() => {
    onDismiss!()
    reset()
  }, [onDismiss, reset])

  const sushi = useSushi()
  const sushiBalance = useTokenBalance(getSushiAddress(sushi))

  let indexPoint = logo.lastIndexOf('/static')
  let lastId = logo.length
  let newSrc = logo.slice(indexPoint, lastId)

  return (
    <Modal>
      <ModalTitle text="My Account" />
      <StyledModalTitle>{account ?? ''} </StyledModalTitle>
      <ModalContent>
        <Spacer />

        <div style={{ display: 'flex' }}>
          <StyledBalanceWrapper>
            <CardIcon>
              <span style={{ display: 'flex' }}>
                <img src={newSrc} />
              </span>
            </CardIcon>
            <StyledBalance>
              <Value colorText="dark" value={getBalanceNumber(sushiBalance)} />
              <Label colorText="dark" text="ENIGMA Balance" />
            </StyledBalance>
          </StyledBalanceWrapper>
        </div>

        <Spacer />
        <Button
          href={`https://scan.9mm.pro/address/${account}`}
          text="View on Pulsechain"
          variant="secondary"
        />
        <Spacer />
        <Button
          onClick={handleSignOutClick}
          text="Sign out"
          variant="secondary"
        />
      </ModalContent>
      <ModalActions>
        <Button onClick={onDismiss} text="Cancel" />
      </ModalActions>
    </Modal>
  )
}

const StyledBalance = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  color: #d16c00;
`

// const CardIcon = styled.div`
// align-items: center;
// display: flex;
// `

const StyledBalanceWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  color: #d16c00;
  margin-bottom: ${(props) => props.theme.spacing[4]}px;
  img {
    max-height: 80px;
  }
`

const StyledModalTitle = styled.div`
  align-items: center;
  color: ${(props) => props.theme.color.grey[600]};
  display: flex;
  font-size: 18px;
  font-weight: 700;
  justify-content: center;
`
export default AccountModal
