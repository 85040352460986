
export const getTokenDecimals = (tokenName: string) => {

    let decimals = 18

    if (tokenName == 'MANIA')
        decimals = 10
    else if (tokenName == 'HEX')
        decimals = 8

    return decimals
}

export const getTokenDecimalsDelta = (tokenName: string) => {
    return 18 - getTokenDecimals(tokenName)
}