import React from 'react'
import styled from 'styled-components'

interface SushiIconProps {
  size?: number
  v1?: boolean
  v2?: boolean
  v3?: boolean
  children: string
}

const StyledSushiIcon = styled.img<{ size: number }>`
  height: ${(props) => props.size}px;
`

const SushiIcon: React.FC<SushiIconProps> = ({
  size = 70,
  v1,
  v2,
  v3,
  children,
}) => <StyledSushiIcon size={size} src={children} alt="" />

export default SushiIcon
