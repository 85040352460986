import React, { useEffect } from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { useWallet } from 'use-wallet'

import logo from '../../assets/img/logo-big.png'

import Button from '../../components/Button'
import Page from '../../components/Page'
import PageHeader from '../../components/PageHeader'
import WalletProviderModal from '../../components/WalletProviderModal'

import useModal from '../../hooks/useModal'
import useSushi from '../../hooks/useSushi'
import { getFarms } from '../../sushi/utils'

import Farm from '../Farm'

import FarmCards from './components/FarmCards'

const Farms: React.FC = () => {
  const { path } = useRouteMatch()
  const { account } = useWallet()
  const sushi = useSushi()
  const [onPresentWalletProviderModal] = useModal(<WalletProviderModal />)
  const isPathFarms = path === '/farms'
  const availablePoolsForPoolsPage = [
    'MANIA',
    'ENIGMA',
    'WPLS',
    'PLSX',
    'HEX',
    'INC',
    'PEPE',
    'SHIB',
    'XEN',
    'PSB',
    'HELGO',
  ]

  const availablePoolsForFarmsPage = [
    'ENIGMA-OG 9mm',
    'ENIGMA-WETH 9mm',
    'ENIGMA-HEX',
    'ENIGMA-PLS',
    'ENIGMA-PLS 9mm',
    'ENIGMA-pDAI V2',
    'MANIA-PLS',
    'MANIA-PLS 9mm',
    'MANIA-WBTC 9mm',
    'MANIA-HOA 9mm',
    'HOA-DAI 9mm',
    'DWB-DAI 9mm',
    'PEPE-pPEPE 9mm',
    'MANIA-pDAI V2',
    'MANIA-HEX',
    'PLSX-PLS',
    'USDC-PLS',
    'INC-PLS',
    'PEPE-PLS',
    'SHIB-PLS',
    'PRS-PLS',
  ]

  useEffect(()=> {
    getFarms(sushi)
  }, [path])
  

  return (
    <Switch>
      <Page>
        {!!account ? (
          <>
            <Route exact path={path}>
              <PageHeader
                icon={logo}
                subtitle="Mine ENIGMA by staking tokens"
                title="Select Enigma Diamond Mine"
              />
              <FarmCards
                allowedNames={
                  isPathFarms
                    ? availablePoolsForFarmsPage
                    : availablePoolsForPoolsPage
                }
              />
            </Route>
            <Route path={`${path}/:farmId`}>
              <Farm />
            </Route>
          </>
        ) : (
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              flex: 1,
              justifyContent: 'center',
            }}
          >
            <Button
              onClick={onPresentWalletProviderModal}
              text="🔓 Unlock Wallet"
            />
          </div>
        )}
      </Page>
    </Switch>
  )
}

export default Farms
