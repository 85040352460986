import React, { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'

interface AddIconProps {
  color?: string
  size?: number
  strokeWidth?: number
}

const StyledAddIcon = styled.svg<AddIconProps>`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;

  path {
    fill: none;
    stroke: ${(props) =>
      props.color ? props.color : props.theme.color.grey[400]};
    stroke-width: ${(props) => (props.strokeWidth ? props.strokeWidth : 2)};
  }
`

const AddIcon: React.FC<AddIconProps> = ({ color, size = 24, strokeWidth }) => {
  const theme = useContext(ThemeContext)
  return (
    <StyledAddIcon
      size={size}
      viewBox="0 0 24 24"
      color={color}
      strokeWidth={strokeWidth}
      theme={theme}
    >
      {/* <path d="M0 0h24v24H0z" fill="none" /> */}
      <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" strokeWidth={strokeWidth} />
    </StyledAddIcon>
  )
}

export default AddIcon
