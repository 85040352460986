import BigNumber from 'bignumber.js'
import { ethers } from 'ethers'

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

const GAS_LIMIT = {
  STAKING: {
    DEFAULT: 200000,
    SNX: 850000,
  },
}

export const getMasterChefAddress = (sushi) => {
  return sushi && sushi.masterChefAddress
}
export const getSushiAddress = (sushi) => {
  return sushi && sushi.sushiAddress
}
export const getWethContract = (sushi) => {
  return sushi && sushi.contracts && sushi.contracts.weth
}

export const getMasterChefContract = (sushi) => {
  return sushi && sushi.contracts && sushi.contracts.masterChef
}
export const getSushiContract = (sushi) => {
  return sushi && sushi.contracts && sushi.contracts.sushi
}

export const getXSushiStakingContract = (sushi) => {
  return sushi && sushi.contracts && sushi.contracts.xSushiStaking
}

export const getFarms = (sushi) => {
  return sushi
    ? sushi.contracts.pools.map(
        ({
          pid,
          name,
          symbol,
          icon,
          tokenAddress,
          tokenSymbol,
          tokenContract,
          lpAddress,
          lpContract,
          isTokenOnly,
          quoteTokenContract,
          quoteTokenWethLpContract,
        }) => ({
          pid,
          id: symbol,
          name,
          lpToken: symbol,
          lpTokenAddress: lpAddress,
          lpContract,
          tokenAddress,
          tokenSymbol,
          tokenContract,
          earnToken: 'ENIGMA',
          earnTokenAddress: sushi.contracts.sushi.options.address,
          icon,
          isTokenOnly,
          quoteTokenContract,
          quoteTokenWethLpContract,
        }),
      )
    : []
}

export const getPoolWeight = async (masterChefContract, pid) => {
  const { allocPoint } = await masterChefContract.methods.poolInfo(pid).call()
  const totalAllocPoint = await masterChefContract.methods
    .totalAllocPoint()
    .call()
  return new BigNumber(allocPoint).div(new BigNumber(totalAllocPoint))
}

export const getEarned = async (masterChefContract, pid, account) => {
  return masterChefContract.methods.pendingEnigma(pid, account).call()
}

export const getTokenPriceInAnotherToken = async (pid, tokenContract, anotherTokenContract, lpContract) => {
  if (!lpContract) return new BigNumber(1);

  // Token decimals
  const tokenDecimals = await tokenContract.methods.decimals().call();
  const anotherTokenDecimals = await anotherTokenContract.methods.decimals().call();

  const tokenAmountInLp = await tokenContract.methods.balanceOf(lpContract.options.address).call() / (10 ** tokenDecimals);
  const anotherTokenAmountInLp = await anotherTokenContract.methods.balanceOf(lpContract.options.address).call() / (10 ** anotherTokenDecimals);

  const tokenPriceInAnotherToken = new BigNumber(anotherTokenAmountInLp).div(tokenAmountInLp);
  return tokenPriceInAnotherToken;
}

export const getTotalLPWethValue = async (
  masterChefContract,
  wethContract,
  lpContract,
  tokenContract,
  pid,
  isTokenOnly,
  quoteTokenContract,
  quoteTokenWethLpContract
) => {
  if (isTokenOnly) {

    if (!quoteTokenContract)
      quoteTokenContract = wethContract

    const quoteTokenPriceInWETH = await getTokenPriceInAnotherToken(pid, quoteTokenContract, wethContract, quoteTokenWethLpContract);

    // Balance  tokens in the masterChefContract
    const balance = await tokenContract.methods
      .balanceOf(masterChefContract.options.address)
      .call()

    // Token decimals
    const tokenDecimals = await tokenContract.methods.decimals().call()

    const tokenPriceInQuoteToken = await getTokenPriceInAnotherToken(pid, tokenContract, quoteTokenContract, lpContract);
    const tokenPriceInWETH = tokenPriceInQuoteToken.times(quoteTokenPriceInWETH);
    const tokenAmount = new BigNumber(balance).div(new BigNumber(10).pow(tokenDecimals))
    const totalWethWorth = new BigNumber(tokenAmount).times(tokenPriceInWETH)

    return {
      tokenAmount: 0,
      wethAmount: 0,
      totalWethValue: totalWethWorth,
      tokenPriceInWeth: tokenPriceInWETH,
      poolWeight: await getPoolWeight(masterChefContract, pid),
    }
  } else {

    if (!quoteTokenContract)
      quoteTokenContract = wethContract

    const quoteTokenPriceInWETH = await getTokenPriceInAnotherToken(pid, quoteTokenContract, wethContract, quoteTokenWethLpContract);

    // Get balance of the token address
    const tokenAmountWholeLP = await tokenContract.methods
      .balanceOf(lpContract.options.address)
      .call()
    const tokenDecimals = await tokenContract.methods.decimals().call()
    // Get the share of lpContract that masterChefContract owns
    const balance = await lpContract.methods
      .balanceOf(masterChefContract.options.address)
      .call()
    // Convert that into the portion of total lpContract = p1
    const totalSupply = await lpContract.methods.totalSupply().call()

    const quoteTokenDecimals = await quoteTokenContract.methods.decimals().call()
    // Get the share of lpContract that masterChefContract owns

    // Get total weth value for the lpContract = w1
    const lpContractQuoteToken = await quoteTokenContract.methods
      .balanceOf(lpContract.options.address)
      .call()
    // Return p1 * w1 * 2
    const portionLp = new BigNumber(balance).div(new BigNumber(totalSupply))
    const lpWethWorth = new BigNumber(lpContractQuoteToken).times(quoteTokenPriceInWETH)
    const totalLpWethValue = portionLp.times(lpWethWorth).times(new BigNumber(2))
    // Calculate
    const tokenAmount = new BigNumber(tokenAmountWholeLP)
      .times(portionLp)
      .div(new BigNumber(10).pow(tokenDecimals))

    const wethAmount = new BigNumber(lpContractQuoteToken)
      .times(portionLp)
      .div(new BigNumber(10).pow(quoteTokenDecimals))

    return {
      tokenAmount,
      wethAmount,
      totalWethValue: totalLpWethValue.div(new BigNumber(10).pow(quoteTokenDecimals)),
      tokenPriceInWeth: wethAmount.div(tokenAmount),
      poolWeight: await getPoolWeight(masterChefContract, pid),
    }
  }
}

export const approve = async (lpContract, masterChefContract, account) => {
  return lpContract.methods
    .approve(masterChefContract.options.address, ethers.constants.MaxUint256)
    .send({ from: account })
}

export const approveAddress = async (lpContract, address, account) => {
  return lpContract.methods
    .approve(address, ethers.constants.MaxUint256)
    .send({ from: account })
}

export const getSushiSupply = async (sushi) => {
  return new BigNumber(await sushi.contracts.sushi.methods.totalSupply().call())
}

export const getSushiSupplyExcludedBurned = async (sushi) => {
  const BURN_ADDRESS = '0x0000000000000000000000000000000000000369'
  const supply = await sushi.contracts.sushi.methods.totalSupply().call()
  const burned = await sushi.contracts.sushi.methods.balanceOf(BURN_ADDRESS).call()
  return new BigNumber(supply).minus(burned)
}

export const getXSushiSupply = async (sushi) => {
  return new BigNumber(
    await sushi.contracts.xSushiStaking.methods.totalSupply().call(),
  )
}

export const stake = async (masterChefContract, pid, amount, account) => {
  return masterChefContract.methods
    .deposit(
      pid,
      new BigNumber(amount).times(new BigNumber(10).pow(18)).toString(),
    )
    .send({ from: account })
    .on('transactionHash', (tx) => {
      console.log(tx)
      return tx.transactionHash
    })
}

export const unstake = async (masterChefContract, pid, amount, account) => {
  return masterChefContract.methods
    .withdraw(
      pid,
      new BigNumber(amount).times(new BigNumber(10).pow(18)).toString(),
    )
    .send({ from: account })
    .on('transactionHash', (tx) => {
      console.log(tx)
      return tx.transactionHash
    })
}
export const harvest = async (masterChefContract, pid, account) => {
  return masterChefContract.methods
    .deposit(pid, '0')
    .send({ from: account })
    .on('transactionHash', (tx) => {
      console.log(tx)
      return tx.transactionHash
    })
}

export const getStaked = async (masterChefContract, pid, account) => {
  try {
    const { amount } = await masterChefContract.methods
      .userInfo(pid, account)
      .call()
    return new BigNumber(amount)
  } catch {
    return new BigNumber(0)
  }
}

export const redeem = async (masterChefContract, account) => {
  let now = new Date().getTime() / 1000
  if (now >= 1597172400) {
    return masterChefContract.methods
      .exit()
      .send({ from: account })
      .on('transactionHash', (tx) => {
        console.log(tx)
        return tx.transactionHash
      })
  } else {
    alert('pool not active')
  }
}

export const enter = async (contract, amount, account) => {
  debugger
  return contract.methods
    .enter(new BigNumber(amount).times(new BigNumber(10).pow(18)).toString())
    .send({ from: account })
    .on('transactionHash', (tx) => {
      console.log(tx)
      return tx.transactionHash
    })
}

export const leave = async (contract, amount, account) => {
  return contract.methods
    .leave(new BigNumber(amount).times(new BigNumber(10).pow(18)).toString())
    .send({ from: account })
    .on('transactionHash', (tx) => {
      console.log(tx)
      return tx.transactionHash
    })
}