import BigNumber from 'bignumber.js/bignumber'

export const SUBTRACT_GAS_LIMIT = 100000

const ONE_MINUTE_IN_SECONDS = new BigNumber(60)
const ONE_HOUR_IN_SECONDS = ONE_MINUTE_IN_SECONDS.times(60)
const ONE_DAY_IN_SECONDS = ONE_HOUR_IN_SECONDS.times(24)
const ONE_YEAR_IN_SECONDS = ONE_DAY_IN_SECONDS.times(365)

export const INTEGERS = {
  ONE_MINUTE_IN_SECONDS,
  ONE_HOUR_IN_SECONDS,
  ONE_DAY_IN_SECONDS,
  ONE_YEAR_IN_SECONDS,
  ZERO: new BigNumber(0),
  ONE: new BigNumber(1),
  ONES_31: new BigNumber('4294967295'), // 2**32-1
  ONES_127: new BigNumber('340282366920938463463374607431768211455'), // 2**128-1
  ONES_255: new BigNumber(
    '115792089237316195423570985008687907853269984665640564039457584007913129639935',
  ), // 2**256-1
  INTEREST_RATE_BASE: new BigNumber('1e18'),
}

export const addressMap = {
  uniswapFactory: '0xc0a47dFe034B400B47bDaD5FecDa2621de6c4d95',
  uniswapFactoryV2: '0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f',
  YFI: '0x0bc529c00C6401aEF6D220BE8C6Ea1667F6Ad93e',
  YCRV: '0xdF5e0e81Dff6FAF3A7e52BA697820c5e32D806A8',
  UNIAmpl: '0xc5be99a02c6857f9eac67bbce58df5572498f40c',
  WETH: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
  UNIRouter: '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D',
  LINK: '0x514910771AF9Ca656af840dff83E8264EcF986CA',
  MKR: '0x9f8f72aa9304c8b593d555f12ef6589cc3a579a2',
  SNX: '0xC011a73ee8576Fb46F5E1c5751cA3B9Fe0af2a6F',
  COMP: '0xc00e94Cb662C3520282E6f5717214004A7f26888',
  LEND: '0x80fB784B7eD66730e8b1DBd9820aFD29931aab03',
  SUSHIYCRV: '0x2C7a51A357d5739C5C74Bf3C96816849d2c9F726',
}

// export const contractAddresses = {
//   sushi: {
//     1: '0x6b3595068778dd592e39a122f4f5a5cf09c90fe2',
//   },
//   masterChef: {
//     1: '0xc2edad668740f1aa35e4d8f227fb8e17dca888cd',
//   },
//   weth: {
//     1: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
//   },
//   xSushi: {
//     1: '0x8798249c2e607446efb7ad49ec89dd1865ff4272'
//   }
// }

/*
SLP Address on mainnet for reference
==========================================
0  USDT 0x0d4a11d5eeaac28ec3f61d100daf4d40471f1852
1  USDC 0xb4e16d0168e52d35cacd2c6185b44281ec28c9dc
2  DAI  0xa478c2975ab1ea89e8196811f51a7b7ade33eb11
3  sUSD 0xf80758ab42c3b07da84053fd88804bcb6baa4b5c
4  COMP 0xcffdded873554f362ac02f8fb1f02e5ada10516f
5  LEND 0xab3f9bf1d81ddb224a2014e98b238638824bcf20
6  SNX  0x43ae24960e5534731fc831386c07755a2dc33d47
7  UMA  0x88d97d199b9ed37c29d846d00d443de980832a22
8  LINK 0xa2107fa5b38d9bbd2c461d6edf11b11a50f6b974
9  BAND 0xf421c3f2e695c2d4c0765379ccace8ade4a480d9
10 AMPL 0xc5be99a02c6857f9eac67bbce58df5572498f40c
11 YFI  0x2fdbadf3c4d5a8666bc06645b8358ab803996e28
12 SUSHI 0xce84867c3c02b05dc570d0135103d3fb9cc19433
*/

// export const supportedPools = [
//   {
//     pid: 12,
//     lpAddresses: {
//       1: '0x795065dCc9f64b5614C407a6EFDC400DA6221FB0',
//     },
//     tokenAddresses: {
//       1: '0x6b3595068778dd592e39a122f4f5a5cf09c90fe2',
//     },
//     name: 'Sushi Party!',
//     symbol: 'SUSHI-ETH SLP',
//     tokenSymbol: 'SUSHI',
//     icon: '🍣',
//   },
//   {
//     pid: 18,
//     lpAddresses: {
//       1: '0xdafd66636e2561b0284edde37e42d192f2844d40', //change this
//     },
//     tokenAddresses: {
//       1: '0x1f9840a85d5af5bf1d1762f925bdaddc4201f984',
//     },
//     name: 'UNI Unicorn',
//     symbol: 'UNI-ETH SLP',
//     tokenSymbol: 'UNI',
//     icon: '🦄',
//   },
//   {
//     pid: 0,
//     lpAddresses: {
//       1: '0x06da0fd433C1A5d7a4faa01111c044910A184553',
//     },
//     tokenAddresses: {
//       1: '0xdac17f958d2ee523a2206206994597c13d831ec7',
//     },
//     name: 'Tether Turtle',
//     symbol: 'USDT-ETH SLP',
//     tokenSymbol: 'USDT',
//     icon: '🐢',
//   },
//   {
//     pid: 1,
//     lpAddresses: {
//       1: '0x397FF1542f962076d0BFE58eA045FfA2d347ACa0',
//     },
//     tokenAddresses: {
//       1: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
//     },
//     name: 'Circle Snail',
//     symbol: 'USDC-ETH SLP',
//     tokenSymbol: 'USDC',
//     icon: '🐌',
//   },
//   {
//     pid: 2,
//     lpAddresses: {
//       1: '0xC3D03e4F041Fd4cD388c549Ee2A29a9E5075882f',
//     },
//     tokenAddresses: {
//       1: '0x6b175474e89094c44da98b954eedeac495271d0f',
//     },
//     name: 'Donald DAI',
//     symbol: 'DAI-ETH SLP',
//     tokenSymbol: 'DAI',
//     icon: '🦆',
//   },
//   {
//     pid: 3,
//     lpAddresses: {
//       1: '0xF1F85b2C54a2bD284B1cf4141D64fD171Bd85539',
//     },
//     tokenAddresses: {
//       1: '0x57ab1ec28d129707052df4df418d58a2d46d5f51',
//     },
//     name: 'Spartan Dollar',
//     symbol: 'SUSD-ETH SLP',
//     tokenSymbol: 'SUSD',
//     icon: '🦍',
//   },
//   {
//     pid: 7,
//     lpAddresses: {
//       1: '0x001b6450083e531a5a7bf310bd2c1af4247e23d4',
//     },
//     tokenAddresses: {
//       1: '0x04fa0d235c4abf4bcf4787af4cf447de572ef828',
//     },
//     name: 'Umami Squid',
//     symbol: 'UMA-ETH SLP',
//     tokenSymbol: 'UMA',
//     icon: '🦑',
//   },
//   {
//     pid: 9,
//     lpAddresses: {
//       1: '0xA75F7c2F025f470355515482BdE9EFA8153536A8',
//     },
//     tokenAddresses: {
//       1: '0xba11d00c5f74255f56a5e366f4f77f5a186d7f55',
//     },
//     name: 'Band-osaurus',
//     symbol: 'BAND-ETH SLP',
//     tokenSymbol: 'BAND',
//     icon: '🦖',
//   },
//   {
//     pid: 8,
//     lpAddresses: {
//       1: '0xC40D16476380e4037e6b1A2594cAF6a6cc8Da967',
//     },
//     tokenAddresses: {
//       1: '0x514910771af9ca656af840dff83e8264ecf986ca',
//     },
//     name: 'Toadie Marine',
//     symbol: 'LINK-ETH SLP',
//     tokenSymbol: 'LINK',
//     icon: '🐸',
//   },
//   {
//     pid: 10,
//     lpAddresses: {
//       1: '0xCb2286d9471cc185281c4f763d34A962ED212962',
//     },
//     tokenAddresses: {
//       1: '0xd46ba6d942050d489dbd938a2c909a5d5039a161',
//     },
//     name: 'Ample Chicks',
//     symbol: 'AMPL-ETH SLP',
//     tokenSymbol: 'AMPL',
//     icon: '🐥',
//   },
//   {
//     pid: 4,
//     lpAddresses: {
//       1: '0x31503dcb60119a812fee820bb7042752019f2355',
//     },
//     tokenAddresses: {
//       1: '0xc00e94cb662c3520282e6f5717214004a7f26888',
//     },
//     name: 'Compound Truffle',
//     symbol: 'COMP-ETH SLP',
//     tokenSymbol: 'COMP',
//     icon: '🍄',
//   },
//   {
//     pid: 5,
//     lpAddresses: {
//       1: '0x5E63360E891BD60C69445970256C260b0A6A54c6',
//     },
//     tokenAddresses: {
//       1: '0x80fb784b7ed66730e8b1dbd9820afd29931aab03',
//     },
//     name: 'Aave Boar',
//     symbol: 'LEND-ETH SLP',
//     tokenSymbol: 'LEND',
//     icon: '🐗',
//   },
//   {
//     pid: 6,
//     lpAddresses: {
//       1: '0xA1d7b2d891e3A1f9ef4bBC5be20630C2FEB1c470',
//     },
//     tokenAddresses: {
//       1: '0xc011a73ee8576fb46f5e1c5751ca3b9fe0af2a6f',
//     },
//     name: 'Synthetic Snake',
//     symbol: 'SNX-ETH SLP',
//     tokenSymbol: 'SNX',
//     icon: '🐍',
//   },
//   {
//     pid: 11,
//     lpAddresses: {
//       1: '0x088ee5007C98a9677165D78dD2109AE4a3D04d0C',
//     },
//     tokenAddresses: {
//       1: '0x0bc529c00c6401aef6d220be8c6ea1667f6ad93e',
//     },
//     name: 'YFI Whale',
//     symbol: 'YFI-ETH SLP',
//     tokenSymbol: 'YFI',
//     icon: '🐋',
//   },
//   {
//     pid: 13,
//     lpAddresses: {
//       1: '0x611cde65dea90918c0078ac0400a72b0d25b9bb1',
//     },
//     tokenAddresses: {
//       1: '0x408e41876cccdc0f92210600ef50372656052a38',
//     },
//     name: 'REN Rhino',
//     symbol: 'REN-ETH SLP',
//     tokenSymbol: 'REN',
//     icon: '🦏',
//   },
//   {
//     pid: 14,
//     lpAddresses: {
//       1: '0xaad22f5543fcdaa694b68f94be177b561836ae57',
//     },
//     tokenAddresses: {
//       1: '0x68A118Ef45063051Eac49c7e647CE5Ace48a68a5',
//     },
//     name: 'BASED Bull',
//     symbol: 'BASED-sUSD SLP',
//     tokenSymbol: 'BASED',
//     icon: '🐂',
//   },
//   {
//     pid: 15,
//     lpAddresses: {
//       1: '0x117d4288b3635021a3d612fe05a3cbf5c717fef2',
//     },
//     tokenAddresses: {
//       1: '0x476c5E26a75bd202a9683ffD34359C0CC15be0fF',
//     },
//     name: 'SRM Shark',
//     symbol: 'SRM-ETH SLP',
//     tokenSymbol: 'SRM',
//     icon: '🦈',
//   },
//   {
//     pid: 16,
//     lpAddresses: {
//       1: '0x95b54c8da12bb23f7a5f6e26c38d04acc6f81820',
//     },
//     tokenAddresses: {
//       1: '0xaba8cac6866b83ae4eec97dd07ed254282f6ad8a',
//     },
//     name: 'YAMv2',
//     symbol: 'YAMv2-ETH SLP',
//     tokenSymbol: 'YAMv2',
//     icon: '🍠',
//   },
//   {
//     pid: 17,
//     lpAddresses: {
//       1: '0x58Dc5a51fE44589BEb22E8CE67720B5BC5378009',
//     },
//     tokenAddresses: {
//       1: '0xD533a949740bb3306d119CC777fa900bA034cd52',
//     },
//     name: 'CRV Crocodile',
//     symbol: 'CRV-ETH SLP',
//     tokenSymbol: 'CRV',
//     icon: '🐊',
//   },
//   {
//     pid: 19,
//     lpAddresses: {
//       1: '0x36e2FCCCc59e5747Ff63a03ea2e5C0c2C14911e7',
//     },
//     tokenAddresses: {
//       1: '0x8798249c2E607446EfB7Ad49eC89dD1865Ff4272',
//     },
//     name: "Chef's Menu",
//     symbol: 'xSUSHI-ETH SLP',
//     tokenSymbol: 'xSUSHI',
//     icon: '👨🏻‍🍳',
//   },
//   {
//    pid: 20,
//    lpAddresses: {
//      1: '0x0Cfe7968e7c34A51217a7C9b9dc1690F416E027e',
//    },
//    tokenAddresses: {
//      1: '0x5d3a536E4D6DbD6114cc1Ead35777bAB948E3643',
//    },
//    name: "Chef's Menu",
//    symbol: 'cDAI-DAI SLP',
//    tokenSymbol: 'cDAI',
//    icon: '‍👨🏻‍🍳',
//  },
//  {
//    pid: 21,
//    lpAddresses: {
//      1: '0xceff51756c56ceffca006cd410b03ffc46dd3a58',
//    },
//    tokenAddresses: {
//      1: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
//    },
//    name: "Chef's Menu",
//    symbol: 'WBTC-ETH SLP',
//    tokenSymbol: 'WBTC',
//    icon: '👨🏻‍🍳',
//  },
//  {
//    pid: 22,
//    lpAddresses: {
//      1: '0xf169CeA51EB51774cF107c88309717ddA20be167',
//    },
//    tokenAddresses: {
//      1: '0x2ba592F78dB6436527729929AAf6c908497cB200',
//    },
//    name: "Chef's Menu",
//    symbol: 'CREAM-ETH SLP',
//    tokenSymbol: 'CREAM',
//    icon: '👨🏻‍🍳',
//  },
//  {
//    pid: 23,
//    lpAddresses: {
//      1: '0x17b3C19Bd640a59E832AB73eCcF716CB47419846',
//    },
//    tokenAddresses: {
//      1: '0xD5525D397898e5502075Ea5E830d8914f6F0affe',
//    },
//    name: "Chef's Menu",
//    symbol: 'MEME-ETH SLP',
//    tokenSymbol: 'MEME',
//    icon: '👨🏻‍🍳',
//  },
//  {
//    pid: 24,
//    lpAddresses: {
//      1: '0xFcff3b04C499A57778ae2CF05584ab24278A7FCb',
//    },
//    tokenAddresses: {
//      1: '0x0d438f3b5175bebc262bf23753c1e53d03432bde',
//    },
//    name: "Chef's Menu",
//    symbol: 'wNXM-ETH SLP',
//    tokenSymbol: 'wNXM',
//    icon: '👨🏻‍🍳',
//  },
//  {
//    pid: 25,
//    lpAddresses: {
//      1: '0x382c4a5147Fd4090F7BE3A9Ff398F95638F5D39E',
//    },
//    tokenAddresses: {
//      1: '0x5dbcf33d8c2e976c6b560249878e6f1491bca25c',
//    },
//    name: "Chef's Menu",
//    symbol: 'yUSD-ETH SLP',
//    tokenSymbol: 'yUSD',
//    icon: '👨🏻‍🍳',
//  },
//  {
//    pid: 26,
//    lpAddresses: {
//      1: '0x2024324a99231509a3715172d4f4f4e751b38d4d',
//    },
//    tokenAddresses: {
//      1: '0x5dbcf33d8c2e976c6b560249878e6f1491bca25c',
//    },
//    name: "Chef's Menu",
//    symbol: 'yUSD-WBTC SLP',
//    tokenSymbol: 'yUSD',
//    icon: '👨🏻‍🍳',
//  },
//  {
//    pid: 27,
//    lpAddresses: {
//      1: '0x0be88ac4b5C81700acF3a606a52a31C261a24A35',
//    },
//    tokenAddresses: {
//      1: '0xa0b73e1ff0b80914ab6fe0444e65848c4c34450b',
//    },
//    name: "Chef's Menu",
//    symbol: 'CRO-ETH SLP',
//    tokenSymbol: 'CRO',
//    icon: '👨🏻‍🍳',
//  },
//  {
//    pid: 28,
//    lpAddresses: {
//      1: '0x518d6CE2D7A689A591Bf46433443C31615b206C5',
//    },
//    tokenAddresses: {
//      1: '0xeb4c2781e4eba804ce9a9803c67d0893436bb27d',
//    },
//    name: "Chef's Menu",
//    symbol: 'renBTC-WBTC SLP',
//    tokenSymbol: 'renBTC',
//    icon: '👨🏻‍🍳',
//  },
// ]

//SETTINGS
//export const CHAIN_ID = 943 //Pulsechain Testnet
export const CHAIN_ID = 369 //Pulsechain Testnet

export const contractAddresses = {
  sushi: {
    369: '0x85F1724A1a21a2e4F27C2ffb54a976D5857b2FA0', //ENIGMA
  },
  masterChef: {
    369: '0x5bb851553247d04D8c7C625495DF5d5292b40691',
  },
  weth: {
    369: '0xA1077a294dDE1B09bB078844df40758a5D0f9a27', //Wrapped PLS
  },
  xSushi: {
    369: '0x8798249c2e607446efb7ad49ec89dd1865ff4272', // NOTE: Not in use
  },
}

export const supportedPools = [
  {
    pid: 24,
    lpAddresses: {
      369: '0xdb35fbd36125f6ec51eab7af477d76d5ce5780b2', // ENIGMA-OG
    },
    tokenAddresses: {
      369: '0x85F1724A1a21a2e4F27C2ffb54a976D5857b2FA0', // ENIGMA
    },
    quoteTokenAddresses: {
      369: '0xF84b84dAAce6Ac00DbBAed26CA32Ff3570Aaf66C', // OG
    },
    quoteTokenWethLpAddresses: {
      369: '0xfedef4281f36c355572f49698546ca70fa521d1d', // OG-PLS
    },
    name: 'ENIGMA-OG 9mm',
    symbol: 'ENIGMA-OG LP 9mm',
    tokenSymbol: 'ENIGMA-OG 9mm',
    icon: '🎲',
  },
  {
    pid: 27,
    lpAddresses: {
      369: '0x395a9F7D60063c726DA704c724811db298f0Ada3', // ENIGMA-PLS 9mm
    },
    tokenAddresses: {
      369: '0x85F1724A1a21a2e4F27C2ffb54a976D5857b2FA0', // ENIGMA
    },
    name: 'ENIGMA-PLS 9mm',
    symbol: 'ENIGMA-PLS LP 9mm',
    tokenSymbol: 'ENIGMA-PLS 9mm',
    icon: '💎',
  },
  {
    pid: 25,
    lpAddresses: {
      369: '0x18bd738804Df47438e2Ad5e096Cf4145f9F397C9', // ENIGMA-WETH 9mm
    },
    tokenAddresses: {
      369: '0x85F1724A1a21a2e4F27C2ffb54a976D5857b2FA0', // ENIGMA
    },
    quoteTokenAddresses: {
      369: '0x02DcdD04e3F455D838cd1249292C58f3B79e3C3C', // WETH
    },
    quoteTokenWethLpAddresses: {
      369: '0x364259a98543c1919d2f54a8d8da1f6c055c1514', // WETH-PLS
    },
    name: 'ENIGMA-WETH 9mm',
    symbol: 'ENIGMA-WETH LP 9mm',
    tokenSymbol: 'ENIGMA-WETH 9mm',
    icon: '🎲',
  },
  {
    pid: 28,
    lpAddresses: {
      369: '0x17CDf8d84c2456C4D3f155286652fc283F06751D', // MANIA-PLS 9mm
    },
    tokenAddresses: {
      369: '0x8dcf280e9D3f8B988Bba0000428a02C860E50bFf', // MANIA
    },
    name: 'MANIA-PLS 9mm',
    symbol: 'MANIA-PLS LP 9mm',
    tokenSymbol: 'MANIA-PLS 9mm',
    icon: '🎲',
  },
  {
    pid: 26,
    lpAddresses: {
      369: '0x7333c77795464f88976917084C40cf3Ea7b28FdC', // MANIA-WBTC 9mm
    },
    tokenAddresses: {
      369: '0x8dcf280e9D3f8B988Bba0000428a02C860E50bFf', // MANIA
    },
    quoteTokenAddresses: {
      369: '0xb17D901469B9208B17d916112988A3FeD19b5cA1', // WBTC
    },
    quoteTokenWethLpAddresses: {
      369: '0xDB82b0919584124A0Eb176ab136A0Cc9F148B2D1', // WBTC-PLS
    },
    name: 'MANIA-WBTC 9mm',
    symbol: 'MANIA-WBTC LP 9mm',
    tokenSymbol: 'MANIA-WBTC 9mm',
    icon: '🎲',
  },
    {
    pid: 31,
    lpAddresses: {
      369: '0x4F0b08e7f0e6aC96139986246EB5adED234cF728', // HOA-DAI 9mm
    },
    tokenAddresses: {
      369: '0x7901a3569679AEc3501dbeC59399F327854a70fe', // HOA
    },
    quoteTokenAddresses: {
      369: '0x8dcf280e9D3f8B988Bba0000428a02C860E50bFf', // MANIA
    },
    quoteTokenWethLpAddresses: {
      369: '0x17CDf8d84c2456C4D3f155286652fc283F06751D', // MANIA-PLS
    },
    name: 'MANIA-HOA 9mm',
    symbol: 'MANIA-HOA LP 9mm',
    tokenSymbol: 'MANIA-HOA 9mm',
    icon: '🎲',
  },
  {
    pid: 29,
    lpAddresses: {
      369: '0xbd55d360d891ce4cccafb15c2af85a42f2a63980', // HOA-DAI 9mm
    },
    tokenAddresses: {
      369: '0x7901a3569679AEc3501dbeC59399F327854a70fe', // HOA
    },
    quoteTokenAddresses: {
      369: '0xefD766cCb38EaF1dfd701853BFCe31359239F305', // DAI
    },
    quoteTokenWethLpAddresses: {
      369: '0xE56043671df55dE5CDf8459710433C10324DE0aE', // DAI-PLS
    },
    name: 'HOA-DAI 9mm',
    symbol: 'HOA-DAI LP 9mm',
    tokenSymbol: 'HOA-DAI 9mm',
    icon: '🎲',
  },
  {
    pid: 30,
    lpAddresses: {
      369: '0xF91069de38679067b199754BCf31872d82a32011', // DWB-DAI 9mm
    },
    tokenAddresses: {
      369: '0xAEbcD0F8f69ECF9587e292bdfc4d731c1abedB68', // DWB
    },
    quoteTokenAddresses: {
      369: '0xefD766cCb38EaF1dfd701853BFCe31359239F305', // DAI
    },
    quoteTokenWethLpAddresses: {
      369: '0xE56043671df55dE5CDf8459710433C10324DE0aE', // DAI-PLS
    },
    name: 'DWB-DAI 9mm',
    symbol: 'DWB-DAI LP 9mm',
    tokenSymbol: 'DWB-DAI 9mm',
    icon: '🎲',
  },
    {
    pid: 32,
    lpAddresses: {
      369: '0xca4420F7baeC8B9F58bb72Aafe27F939e00951E9', // PEPE-pPEPE 9mm
    },
    tokenAddresses: {
      369: '0x4d3AeA379b7689E0Cb722826C909Fab39E54123d', // pPEPE
    },
    quoteTokenAddresses: {
      369: '0x6982508145454Ce325dDbE47a25d4ec3d2311933', // pPEPE
    },
    quoteTokenWethLpAddresses: {
      369: '0x11659a752ac07452b9f139b897c917338e2dfb16', // pPEPE-PLS
    },
    name: 'PEPE-pPEPE 9mm',
    symbol: 'PEPE-pPEPE LP 9mm',
    tokenSymbol: 'PEPE-pPEPE 9mm',
    icon: '🎲',
  },
  // {
  //   pid: 1,
  //   lpAddresses: {
  //     369: '0x2Ca1CcD3875FCE93bAA143a7a5A80b2CD65e17e5', // ENIGMA-PLS
  //   },
  //   tokenAddresses: {
  //     369: '0x85F1724A1a21a2e4F27C2ffb54a976D5857b2FA0', // ENIGMA
  //   },
  //   name: 'ENIGMA-PLS',
  //   symbol: 'ENIGMA-PLS LP',
  //   tokenSymbol: 'ENIGMA-PLS',
  //   icon: '💎',
  // },
  // {
  //   pid: 17,
  //   lpAddresses: {
  //     369: '0x17066D9b7bB6dB5Abbeb10250272cfdE9B9A99be', // ENIGMA-HEX
  //   },
  //   tokenAddresses: {
  //     369: '0x85F1724A1a21a2e4F27C2ffb54a976D5857b2FA0', // ENIGMA
  //   },
  //   quoteTokenAddresses: {
  //     369: '0x2b591e99afE9f32eAA6214f7B7629768c40Eeb39', // HEX
  //   },
  //   quoteTokenWethLpAddresses: {
  //     369: '0xf1F4ee610b2bAbB05C635F726eF8B0C568c8dc65', // HEX-PLS
  //   },
  //   name: 'ENIGMA-HEX',
  //   symbol: 'ENIGMA-HEX LP',
  //   tokenSymbol: 'ENIGMA-HEX',
  //   icon: '🎲',
  // },
  // {
  //   pid: 22,
  //   lpAddresses: {
  //     369: '0xDFe69DEa1FC31D24f2E5Ef67D4Cfd5D297A48ceE', // ENIGMA-pDAI
  //   },
  //   tokenAddresses: {
  //     369: '0x85F1724A1a21a2e4F27C2ffb54a976D5857b2FA0', // ENIGMA
  //   },
  //   quoteTokenAddresses: {
  //     369: '0x6B175474E89094C44Da98b954EedeAC495271d0F', // pDAI
  //   },
  //   quoteTokenWethLpAddresses: {
  //     369: '0x947b4633e32E0c7f2c76753B43F008480715416D', // pDAI-PLS
  //   },
  //   name: 'ENIGMA-pDAI V2',
  //   symbol: 'ENIGMA-pDAI LP',
  //   tokenSymbol: 'ENIGMA-pDAI V2',
  //   icon: '🎲',
  // },
  // {
  //   pid: 0,
  //   lpAddresses: {
  //     369: '0x96dCf5838107aB6B5ED155cc31D317dDB5200F08', // MANIA-PLS
  //   },
  //   tokenAddresses: {
  //     369: '0x8dcf280e9D3f8B988Bba0000428a02C860E50bFf', // MANIA
  //   },
  //   name: 'MANIA-PLS',
  //   symbol: 'MANIA-PLS LP',
  //   tokenSymbol: 'MANIA-PLS',
  //   icon: '🎲',
  // },
  // {
  //   pid: 18,
  //   lpAddresses: {
  //     369: '0xe6dC22184cc1A0100937490a76d01723B9c2D1f8', // MANIA-HEX
  //   },
  //   tokenAddresses: {
  //     369: '0x8dcf280e9D3f8B988Bba0000428a02C860E50bFf', // MANIA
  //   },
  //   quoteTokenAddresses: {
  //     369: '0x2b591e99afE9f32eAA6214f7B7629768c40Eeb39', // HEX
  //   },
  //   quoteTokenWethLpAddresses: {
  //     369: '0xf1F4ee610b2bAbB05C635F726eF8B0C568c8dc65', // HEX-PLS
  //   },
  //   name: 'MANIA-HEX',
  //   symbol: 'MANIA-HEX LP',
  //   tokenSymbol: 'MANIA-HEX',
  //   icon: '🎲',
  // },
  // {
  //   pid: 23,
  //   lpAddresses: {
  //     369: '0xE5aFA00A12a869EA8dCf59FBFB1E22465Aa87129', // MANIA-pDAI
  //   },
  //   tokenAddresses: {
  //     369: '0x8dcf280e9D3f8B988Bba0000428a02C860E50bFf', // MANIA
  //   },
  //   quoteTokenAddresses: {
  //     369: '0x6B175474E89094C44Da98b954EedeAC495271d0F', // pDAI
  //   },
  //   quoteTokenWethLpAddresses: {
  //     369: '0x947b4633e32E0c7f2c76753B43F008480715416D', // pDAI-PLS
  //   },
  //   name: 'MANIA-pDAI V2',
  //   symbol: 'MANIA-pDAI LP',
  //   tokenSymbol: 'MANIA-pDAI V2',
  //   icon: '🎲',
  // },
  // {
  //   pid: 16,
  //   lpAddresses: {
  //     369: '0xA5b246eb2c9A373Ad9b826162632E73a339e0232', // MANIA-PLS
  //   },
  //   tokenAddresses: {
  //     369: '0x8dcf280e9D3f8B988Bba0000428a02C860E50bFf', // MANIA
  //   },
  //   wethLpAddresses: {
  //     369: '0x2Ca1CcD3875FCE93bAA143a7a5A80b2CD65e17e5', // ENIGMA-PLS
  //   },
  //   wethLpAddresses2: {
  //     369: '0x96dCf5838107aB6B5ED155cc31D317dDB5200F08', // MANIA-PLS
  //   },
  //   name: 'MANIA-ENIGMA',
  //   symbol: 'MANIA-ENIGMA LP',
  //   tokenSymbol: 'MANIA-ENIGMA',
  //   icon: '🎲',
  // },
  // {
  //   pid: 2,
  //   lpAddresses: {
  //     369: '0x1b45b9148791d3a104184Cd5DFE5CE57193a3ee9', //PLS-PLSX
  //   },
  //   tokenAddresses: {
  //     369: '0x95B303987A60C71504D99Aa1b13B4DA07b0790ab', //PLSX
  //   },
  //   name: 'PLSX-PLS',
  //   symbol: 'PLSX-PLS LP',
  //   tokenSymbol: 'PLSX-PLS',
  //   icon: '🎲',
  // },
  // {
  //   pid: 3,
  //   lpAddresses: {
  //     369: '0x6753560538ECa67617A9Ce605178F788bE7E524E', //PLS-USDC
  //   },
  //   tokenAddresses: {
  //     369: '0x15D38573d2feeb82e7ad5187aB8c1D52810B1f07', //USDC
  //   },
  //   name: 'USDC-PLS',
  //   symbol: 'USDC-PLS LP',
  //   tokenSymbol: 'USDC-PLS',
  //   icon: '🎲',
  // },
  // {
  //   pid: 4,
  //   lpAddresses: {
  //     369: '0xf808Bb6265e9Ca27002c0A04562Bf50d4FE37EAA', //PLS-INC
  //   },
  //   tokenAddresses: {
  //     369: '0x2fa878Ab3F87CC1C9737Fc071108F904c0B0C95d', //INC
  //   },
  //   name: 'INC-PLS',
  //   symbol: 'INC-PLS LP',
  //   tokenSymbol: 'INC-PLS',
  //   icon: '💎',
  // },
  // {
  //   pid: 5,
  //   lpAddresses: {
  //     369: '0xDDAa0F4Bf2eAeE8e13B2045d03075C1822856DC6', //PLS-PEPE
  //   },
  //   tokenAddresses: {
  //     369: '0x6982508145454Ce325dDbE47a25d4ec3d2311933', //PEPE
  //   },
  //   name: 'PEPE-PLS',
  //   symbol: 'PEPE-PLS LP',
  //   tokenSymbol: 'PEPE-PLS',
  //   icon: '💎',
  // },
  // {
  //   pid: 6,
  //   lpAddresses: {
  //     369: '0xe11Ae46AA5200984d59FF54F3b2c7Ff6AC6EF749', //PLS-SHIB
  //   },
  //   tokenAddresses: {
  //     369: '0x95aD61b0a150d79219dCF64E1E6Cc01f0B64C4cE', //SHIB
  //   },
  //   name: 'SHIB-PLS',
  //   symbol: 'SHIB-PLS LP',
  //   tokenSymbol: 'SHIB-PLS',
  //   icon: '💎',
  // },
  // {
  //   pid: 19,
  //   lpAddresses: {
  //     369: '0x53264c3eE2e1B1f470C9884e7f9AE03613868a96', //PLS-PRS
  //   },
  //   tokenAddresses: {
  //     369: '0xb6B57227150a7097723e0C013752001AaD01248F',
  //   },
  //   name: 'PRS-PLS',
  //   symbol: 'PRS-PLS LP',
  //   tokenSymbol: 'PRS-PLS',
  //   icon: '💎',
  // },

  //Pools
  {
    pid: 8,
    isTokenOnly: true,
    lpAddresses: {
      369: '0x2Ca1CcD3875FCE93bAA143a7a5A80b2CD65e17e5', //ENIGMA-PLS
    },
    tokenAddresses: {
      369: '0x85F1724A1a21a2e4F27C2ffb54a976D5857b2FA0', //ENIGMA
    },
    name: 'ENIGMA',
    symbol: 'ENIGMA',
    tokenSymbol: 'ENIGMA',
    icon: '💎',
  },
  {
    pid: 7,
    isTokenOnly: true,
    lpAddresses: {
      369: '0x96dCf5838107aB6B5ED155cc31D317dDB5200F08', //MANIA
    },
    tokenAddresses: {
      369: '0x8dcf280e9D3f8B988Bba0000428a02C860E50bFf', //MANIA-PLS
    },
    name: 'MANIA',
    symbol: 'MANIA',
    tokenSymbol: 'MANIA',
    icon: '💎',
  },
  {
    pid: 9,
    isTokenOnly: true,
    lpAddresses: {
      369: '0x6753560538ECa67617A9Ce605178F788bE7E524E', //WPLS
    },
    tokenAddresses: {
      369: '0xA1077a294dDE1B09bB078844df40758a5D0f9a27',
    },
    name: 'WPLS',
    symbol: 'WPLS',
    tokenSymbol: 'WPLS',
    icon: '💎',
  },
  {
    pid: 11,
    isTokenOnly: true,
    lpAddresses: {
      369: '0xf1F4ee610b2bAbB05C635F726eF8B0C568c8dc65', //HEX-PLS
    },
    tokenAddresses: {
      369: '0x2b591e99afE9f32eAA6214f7B7629768c40Eeb39', //HEX
    },
    name: 'HEX',
    symbol: 'HEX',
    tokenSymbol: 'HEX',
    icon: '💎',
  },
  // {
  //   pid: 10,
  //   isTokenOnly: true,
  //   lpAddresses: {
  //     369: '0x1b45b9148791d3a104184Cd5DFE5CE57193a3ee9', //PLSX-PLS
  //   },
  //   tokenAddresses: {
  //     369: '0x95B303987A60C71504D99Aa1b13B4DA07b0790ab', //PLSX
  //   },
  //   name: 'PLSX',
  //   symbol: 'PLSX',
  //   tokenSymbol: 'PLSX',
  //   icon: '💎',
  // },
  // {
  //   pid: 12,
  //   isTokenOnly: true,
  //   lpAddresses: {
  //     369: '0xf808Bb6265e9Ca27002c0A04562Bf50d4FE37EAA', //INC-PLS
  //   },
  //   tokenAddresses: {
  //     369: '0x2fa878Ab3F87CC1C9737Fc071108F904c0B0C95d', //INC
  //   },
  //   name: 'INC',
  //   symbol: 'INC',
  //   tokenSymbol: 'INC',
  //   icon: '💎',
  // },
  // {
  //   pid: 13,
  //   isTokenOnly: true,
  //   lpAddresses: {
  //     369: '0xDDAa0F4Bf2eAeE8e13B2045d03075C1822856DC6', //PEPE-PLS
  //   },
  //   tokenAddresses: {
  //     369: '0x6982508145454Ce325dDbE47a25d4ec3d2311933', //PEPE
  //   },
  //   name: 'PEPE',
  //   symbol: 'PEPE',
  //   tokenSymbol: 'PEPE',
  //   icon: '💎',
  // },
  // {
  //   pid: 14,
  //   isTokenOnly: true,
  //   lpAddresses: {
  //     369: '0xe11Ae46AA5200984d59FF54F3b2c7Ff6AC6EF749', //SHIB-PLS
  //   },
  //   tokenAddresses: {
  //     369: '0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce', //SHIB
  //   },
  //   name: 'SHIB',
  //   symbol: 'SHIB',
  //   tokenSymbol: 'SHIB',
  //   icon: '💎',
  // },
  // {
  //   pid: 15,
  //   isTokenOnly: true,
  //   lpAddresses: {
  //     369: '0x9194fe03E648E1220fA8267D699CeF1A20A6AC88', //XEN-PLS
  //   },
  //   tokenAddresses: {
  //     369: '0x8a7FDcA264e87b6da72D000f22186B4403081A2a', //XEN
  //   },
  //   name: 'XEN',
  //   symbol: 'XEN',
  //   tokenSymbol: 'XEN',
  //   icon: '💎',
  // },
  // {
  //   pid: 20,
  //   isTokenOnly: true,
  //   lpAddresses: {
  //     369: '0xAa01D927D3a4Ea087F1F657974E11dCa8bcc0A54', //PSB-PLS
  //   },
  //   tokenAddresses: {
  //     369: '0xCFDAeCeeF1Da66eC26Cfb5BE1ef3a5b70BD6e37C', //PSB
  //   },
  //   name: 'PSB',
  //   symbol: 'PSB',
  //   tokenSymbol: 'PSB',
  //   icon: '💎',
  // },
  // {
  //   pid: 21,
  //   isTokenOnly: true,
  //   lpAddresses: {
  //     369: '0x2772Cb1AC353b4ae486f5baC196f20DcBd8A097F', //HELGO-HEX
  //   },
  //   tokenAddresses: {
  //     369: '0x0567CA0dE35606E9C260CC2358404B11DE21DB44', //HELGO
  //   },
  //   quoteTokenAddresses: {
  //     369: '0x2b591e99afE9f32eAA6214f7B7629768c40Eeb39', //HEX
  //   },
  //   quoteTokenWethLpAddresses: {
  //     369: '0xf1F4ee610b2bAbB05C635F726eF8B0C568c8dc65', //HEX-PLS
  //   },
  //   name: 'HELGO',
  //   symbol: 'HELGO',
  //   tokenSymbol: 'HELGO',
  //   icon: '💎',
  // },
]
