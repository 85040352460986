import BigNumber from 'bignumber.js'
import { getTokenDecimals} from './getTokenDecimals'

export const getBalanceNumber = (balance: BigNumber, tokenName: string) => {
    let decimals = getTokenDecimals(tokenName)
    const displayBalance = balance.dividedBy(new BigNumber(10).pow(decimals))
    return displayBalance.toNumber()
  }

export const getFullDisplayBalance = (balance: BigNumber, tokenName: string) => {
    let decimals = getTokenDecimals(tokenName)
    return balance.dividedBy(new BigNumber(10).pow(decimals)).toFixed()
}

export const getRawBalanceValue = (val: string, tokenName: string) => {
  const decimals = getTokenDecimals(tokenName)
  return new BigNumber(val).dividedBy(new BigNumber(10).pow(decimals)).toFixed()
}

