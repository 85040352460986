import React from 'react'
import styled from 'styled-components'
import logo from '../../assets/img/R.svg'
import PLSX from '../../assets/img/PLSX.png'
import PLS from '../../assets/img/PLS.png'
import ENIGMA from '../../assets/img/ENIGMA.png'
import MANIA from '../../assets/img/MANIA.png'
import INC from '../../assets/img/INC.png'
import USDC from '../../assets/img/USDC.png'
import PEPE from '../../assets/img/PEPE.png'
import pPEPE from '../../assets/img/pPEPE.png'
import SHIB from '../../assets/img/SHIB.png'
import HEX from '../../assets/img/HEX.png'
import XEN from '../../assets/img/XEN.png'
import PRS from '../../assets/img/PRS.png'
import PSB from '../../assets/img/PSB.png'
import HELGO from '../../assets/img/HELGO.png'
import DAI from '../../assets/img/DAI.png'
import pDAI from '../../assets/img/pDAI.png'
import OG from '../../assets/img/OG.png'
import WETH from '../../assets/img/WETH.png'
import WBTC from '../../assets/img/WBTC.png'
import HOA from '../../assets/img/HOA.png'
import DWB from '../../assets/img/DWB.png'

const HeaderIcon = ({ string = '' }: any) => {
  const delimiter = '-'
  const [firsLogoName, secondLogoName] = string.includes(delimiter)
    ? string.split(delimiter)
    : [string, null]
  let firstImageValue
  let secondImageValue

  switch (firsLogoName) {
    case 'PLS':
    case 'PLS 9mm':
      firstImageValue = PLS
      break
    case 'PLSX':
      firstImageValue = PLSX
      break
    case 'INC':
      firstImageValue = INC
      break
    case 'ENIGMA':
      firstImageValue = ENIGMA
      break
    case 'MANIA':
      firstImageValue = MANIA
      break
    case 'USDC':
      firstImageValue = USDC
      break
    case 'PEPE':
      firstImageValue = PEPE
      break
    case 'SHIB':
      firstImageValue = SHIB
      break
    case 'HEX':
      firstImageValue = HEX
      break
    case 'XEN':
      firstImageValue = XEN
      break
    case 'PRS':
      firstImageValue = PRS
      break
    case 'PSB':
      firstImageValue = PSB
      break
    case 'HELGO':
      firstImageValue = HELGO
      break
    case 'DAI':
    case 'DAI 9mm':
      firstImageValue = DAI
      break  
    case 'pDAI':
    case 'pDAI V2':
      firstImageValue = pDAI
      break
    case 'OG':
    case 'OG 9mm':
      firstImageValue = OG    
      break
    case 'WETH':
    case 'WETH 9mm':
      firstImageValue = WETH
      break
    case 'WBTC':
    case 'WBTC 9mm':
      firstImageValue = WBTC
      break
    case 'HOA':
    case 'HOA 9mm':
      firstImageValue = HOA
      break
    case 'DWB':
    case 'DWB 9mm':
      firstImageValue = DWB
      break
    case 'PEPE':
    case 'PEPE 9mm':
      firstImageValue = PEPE
      break    
    case 'pPEPE':
    case 'pPEPE 9mm':
      firstImageValue = pPEPE
        break   
    default:
      firstImageValue = PLS
  }

  switch (secondLogoName) {
    case 'PLS':
    case 'PLS 9mm':
      secondImageValue = PLS
      break
    case 'PLSX':
      secondImageValue = PLSX
      break
    case 'INC':
      secondImageValue = INC
    break
    case 'ENIGMA':
      secondImageValue = ENIGMA
      break
    case 'MANIA':
      secondImageValue = MANIA
      break
    case 'USDC':
      secondImageValue = USDC
      break
    case 'PEPE':
      secondImageValue = PEPE
      break
    case 'SHIB':
      secondImageValue = SHIB
      break
    case 'HEX':
      secondImageValue = HEX
      break
    case 'XEN':
      secondImageValue = XEN
      break
    case 'PRS':
      secondImageValue = PRS
      break
    case 'PSB':
      secondImageValue = PSB
      break
    case 'HELGO':
      secondImageValue = HELGO
      break
    case 'DAI':
    case 'DAI 9mm':
      secondImageValue = DAI
      break
    case 'pDAI':
    case 'pDAI V2':
      secondImageValue = pDAI
      break
    case 'OG':
    case 'OG 9mm':
      secondImageValue = OG
      break
    case 'WETH':
    case 'WETH 9mm':
      secondImageValue = WETH
      break
    case 'WBTC':
    case 'WBTC 9mm':
      secondImageValue = WBTC
      break
    case 'HOA':
    case 'HOA 9mm':
      secondImageValue = HOA
      break
    case 'DWB':
    case 'DWB 9mm':
      secondImageValue = DWB
      break
    case 'PEPE':
    case 'PEPE 9mm':
      secondImageValue = PEPE
      break    
    case 'pPEPE':
    case 'pPEPE 9mm':
      secondImageValue = pPEPE
      break       
    default:
      secondImageValue = null
  }

  return (
    <IconHeaderWrap>
      <StyledFirstIcon className={!secondImageValue ? 'singleIcon' : null}>
        <img style={{ maxHeight: '70px' }} src={firstImageValue} />
      </StyledFirstIcon>

      {secondImageValue && (
        <StyledSecondIcon>
          <img className="farmCardBackLogo" src={secondImageValue} />
        </StyledSecondIcon>
      )}
    </IconHeaderWrap>
  )
}
const IconHeaderWrap = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
`

const StyledFirstIcon = styled.div`
  margin: 10px 0 0 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  &.singleIcon {
    margin: 0px;
  }
`

const StyledSecondIcon = styled.div`
  position: absolute;
  top: 10px;
  left: 15px;
  z-index: 0;
  display: flex;
`

export default HeaderIcon
