import React, { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { useWallet } from 'use-wallet'
import { provider } from 'web3-core'
import PageHeader from '../../components/PageHeader'
import HeaderIcon from '../../components/HeaderIcon'
import Spacer from '../../components/Spacer'
import useFarm from '../../hooks/useFarm'
import useRedeem from '../../hooks/useRedeem'
import useSushi from '../../hooks/useSushi'
import { getMasterChefContract } from '../../sushi/utils'
import { getContract } from '../../utils/erc20'
import Harvest from './components/Harvest'
import Stake from './components/Stake'
import logo from '../../assets/img/logo-big.png'

const Farm: React.FC = () => {
  const { farmId } = useParams()
  const { pid, lpToken, lpTokenAddress, tokenAddress, earnToken, name, icon, isTokenOnly } =
    useFarm(farmId) || {
      pid: 0,
      lpToken: '',
      lpTokenAddress: '',
      tokenAddress: '',
      earnToken: '',
      name: '',
      icon: '',
      isTokenOnly: false,
    }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const sushi = useSushi()
  const { ethereum } = useWallet()

  const lpContract = useMemo(() => {
    return getContract(ethereum as provider, isTokenOnly ? tokenAddress : lpTokenAddress)
  }, [ethereum, isTokenOnly, lpTokenAddress, tokenAddress])

  const { onRedeem } = useRedeem(getMasterChefContract(sushi))

  const lpTokenName = useMemo(() => {
    return lpToken
  }, [lpToken])

  const earnTokenName = useMemo(() => {
    return earnToken.toUpperCase()
  }, [earnToken])

  let indexPoint = logo.lastIndexOf('/static')
  let lastId = logo.length
  let newSrc = logo.slice(indexPoint, lastId)

  return (
    <>
      <PageHeader
        headerIcon={<HeaderIcon string={name} />}
        subtitle={`Deposit ${lpTokenName} Tokens and earn ${earnTokenName}`}
        title={name}
      />
      <StyledFarm>
        <StyledCardsWrapper>
          <StyledCardWrapper>
            <Harvest pid={pid} />
          </StyledCardWrapper>
          <Spacer />
          <StyledCardWrapper>
            <Stake lpContract={lpContract} pid={pid} tokenName={lpToken}/>
          </StyledCardWrapper>
        </StyledCardsWrapper>
        <Spacer size="lg" />
        <StyledInfo>
          ⭐️ Every time you stake and unstake tokens, the contract will
          automagically harvest ENIGMA rewards for you!
        </StyledInfo>
        <Spacer size="md" />
        <StyledLink
          target="__blank"
          href={
            (() => {
              if (pid === 22 || pid === 23) {
                return isTokenOnly 
                  ? `https://pulsex.mypinata.cloud/ipfs/bafybeidea3ibq4lu5t6vk6ihp4iuznjb3ltsdm5y2shv4atxgyd3d33aim/#/info/v2/token/${tokenAddress}`
                  : `https://pulsex.mypinata.cloud/ipfs/bafybeidea3ibq4lu5t6vk6ihp4iuznjb3ltsdm5y2shv4atxgyd3d33aim/#/info/v2/pool/${lpTokenAddress}`;
              } else if (pid >= 24 || pid === 25 || pid == 26 || pid == 27 || pid == 28) {
                return `https://swap.9mm.pro/info/pool/${lpTokenAddress}`;
              } else {
                return isTokenOnly 
                  ? `https://pulsex.mypinata.cloud/ipfs/bafybeidea3ibq4lu5t6vk6ihp4iuznjb3ltsdm5y2shv4atxgyd3d33aim/#/info/token/${tokenAddress}`
                  : `https://pulsex.mypinata.cloud/ipfs/bafybeidea3ibq4lu5t6vk6ihp4iuznjb3ltsdm5y2shv4atxgyd3d33aim/#/info/pool/${lpTokenAddress}`;
              }
            })()
          }>
          {lpTokenName} Info
        </StyledLink>
      </StyledFarm>
    </>
  )
}

const StyledFarm = styled.div`
  align-items: center;
  display: flex;
  color: ${(props) => props.theme.color.white};
  flex-direction: column;
  @media (max-width: 768px) {
    width: 100%;
  }
`

const StyledCardsWrapper = styled.div`
  display: flex;
  width: 600px;
  color: ${(props) => props.theme.color.white};
  @media (max-width: 768px) {
    width: 100%;
    flex-flow: column nowrap;
    align-items: center;
  }
`

const StyledCardWrapper = styled.div`
  display: flex;
  flex: 1;
  color: #fff;
  flex-direction: column;
  @media (max-width: 768px) {
    width: 80%;
  }
`

const StyledInfo = styled.h3`
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  padding: 0;
  text-align: center;
`

const StyledLink = styled.a`
  color: #fff;
  padding-left: ${(props) => props.theme.spacing[3]}px;
  padding-right: ${(props) => props.theme.spacing[3]}px;
  text-decoration: none;
  &:hover {
    color: ${(props) => props.theme.color.grey[500]};
  }
`

export default Farm
